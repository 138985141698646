import { get } from 'axios';

import cssMap from '../../../../../resources/css-mobile.json';

async function loadStyles() {
    const prefix = '/mobile/css/';

    const pathFontCss = prefix + cssMap['fonts.min.css'];
    const pathIconCss = prefix + cssMap['icon.min.css'];
    const pathMainCss = prefix + cssMap['main.min.css'];

    function setStyles(styles) {
        const { data } = styles;
        const styleTag = document.createElement('style');
        styleTag.textContent = data;
        document.head.appendChild(styleTag);
    }

    function fallbackStyles(url) {
        const linkTag = document.createElement('link');
        linkTag.rel = 'stylesheet'
        linkTag.href = url;
        document.head.appendChild(linkTag);
    }

    try {
        const fontCss = await get(pathFontCss);
        const iconCss = await get(pathIconCss);
        const mainCss = await get(pathMainCss);

        setStyles(fontCss);
        setStyles(iconCss);
        setStyles(mainCss);
        document.head.removeChild(document.head.querySelector('style'));

        document.body.dispatchEvent(new Event('stylesloaded'));
    }
    catch (e) {
        fallbackStyles(pathFontCss);
        fallbackStyles(pathIconCss);
        fallbackStyles(pathMainCss);
        document.head.removeChild(document.head.querySelector('style'));
    }
}

loadStyles();
